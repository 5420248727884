/* Custom Zulip icons generated with webfont-loader */

@font-face {
    font-family: 'zulip-icons';
    src: url("files/zulip-icons.cd62f4da77042e9243bc.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i.zulip-icon {
    font-family: 'zulip-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none;
    font-size: inherit;
    line-height: 100%;

    text-decoration: inherit;
    display: inline-block;
    speak: none;
}

.zulip-icon::before {
    font-family: 'zulip-icons' !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.zulip-icon.zulip-icon-arrow-left-circle::before {
    content: "\f101";
}
.zulip-icon.zulip-icon-close-small::before {
    content: "\f102";
}
.zulip-icon.zulip-icon-close::before {
    content: "\f103";
}
.zulip-icon.zulip-icon-search::before {
    content: "\f104";
}
.zulip-icon.zulip-icon-users::before {
    content: "\f105";
}
.zulip-icon.zulip-icon-account::before {
    content: "\f106";
}
.zulip-icon.zulip-icon-all-messages::before {
    content: "\f107";
}
.zulip-icon.zulip-icon-archive::before {
    content: "\f108";
}
.zulip-icon.zulip-icon-arrow-down::before {
    content: "\f109";
}
.zulip-icon.zulip-icon-at-sign::before {
    content: "\f10a";
}
.zulip-icon.zulip-icon-attachment::before {
    content: "\f10b";
}
.zulip-icon.zulip-icon-bar-chart::before {
    content: "\f10c";
}
.zulip-icon.zulip-icon-bold::before {
    content: "\f10d";
}
.zulip-icon.zulip-icon-bot::before {
    content: "\f10e";
}
.zulip-icon.zulip-icon-building::before {
    content: "\f10f";
}
.zulip-icon.zulip-icon-calendar-clock::before {
    content: "\f110";
}
.zulip-icon.zulip-icon-calendar-days::before {
    content: "\f111";
}
.zulip-icon.zulip-icon-calendar::before {
    content: "\f112";
}
.zulip-icon.zulip-icon-check-x::before {
    content: "\f113";
}
.zulip-icon.zulip-icon-check::before {
    content: "\f114";
}
.zulip-icon.zulip-icon-chevron-down::before {
    content: "\f115";
}
.zulip-icon.zulip-icon-chevron-right::before {
    content: "\f116";
}
.zulip-icon.zulip-icon-circle-x::before {
    content: "\f117";
}
.zulip-icon.zulip-icon-clock::before {
    content: "\f118";
}
.zulip-icon.zulip-icon-code::before {
    content: "\f119";
}
.zulip-icon.zulip-icon-collapse-diagonal::before {
    content: "\f11a";
}
.zulip-icon.zulip-icon-collapse::before {
    content: "\f11b";
}
.zulip-icon.zulip-icon-compose-edit::before {
    content: "\f11c";
}
.zulip-icon.zulip-icon-copy::before {
    content: "\f11d";
}
.zulip-icon.zulip-icon-credit-card::before {
    content: "\f11e";
}
.zulip-icon.zulip-icon-drafts::before {
    content: "\f11f";
}
.zulip-icon.zulip-icon-edit::before {
    content: "\f120";
}
.zulip-icon.zulip-icon-exclamation-circle::before {
    content: "\f121";
}
.zulip-icon.zulip-icon-expand-diagonal::before {
    content: "\f122";
}
.zulip-icon.zulip-icon-expand::before {
    content: "\f123";
}
.zulip-icon.zulip-icon-external-link::before {
    content: "\f124";
}
.zulip-icon.zulip-icon-eye::before {
    content: "\f125";
}
.zulip-icon.zulip-icon-f-globe::before {
    content: "\f126";
}
.zulip-icon.zulip-icon-file-check::before {
    content: "\f127";
}
.zulip-icon.zulip-icon-file-text::before {
    content: "\f128";
}
.zulip-icon.zulip-icon-follow::before {
    content: "\f129";
}
.zulip-icon.zulip-icon-forward-message::before {
    content: "\f12a";
}
.zulip-icon.zulip-icon-gear::before {
    content: "\f12b";
}
.zulip-icon.zulip-icon-gif::before {
    content: "\f12c";
}
.zulip-icon.zulip-icon-git-pull-request::before {
    content: "\f12d";
}
.zulip-icon.zulip-icon-globe::before {
    content: "\f12e";
}
.zulip-icon.zulip-icon-grip-vertical::before {
    content: "\f12f";
}
.zulip-icon.zulip-icon-handshake::before {
    content: "\f130";
}
.zulip-icon.zulip-icon-hash::before {
    content: "\f131";
}
.zulip-icon.zulip-icon-hashtag::before {
    content: "\f132";
}
.zulip-icon.zulip-icon-heading-triangle-right::before {
    content: "\f133";
}
.zulip-icon.zulip-icon-heart::before {
    content: "\f134";
}
.zulip-icon.zulip-icon-help-bigger::before {
    content: "\f135";
}
.zulip-icon.zulip-icon-help::before {
    content: "\f136";
}
.zulip-icon.zulip-icon-hide::before {
    content: "\f137";
}
.zulip-icon.zulip-icon-house::before {
    content: "\f138";
}
.zulip-icon.zulip-icon-inbox::before {
    content: "\f139";
}
.zulip-icon.zulip-icon-info::before {
    content: "\f13a";
}
.zulip-icon.zulip-icon-inherit::before {
    content: "\f13b";
}
.zulip-icon.zulip-icon-italic::before {
    content: "\f13c";
}
.zulip-icon.zulip-icon-keyboard::before {
    content: "\f13d";
}
.zulip-icon.zulip-icon-language::before {
    content: "\f13e";
}
.zulip-icon.zulip-icon-life-buoy::before {
    content: "\f13f";
}
.zulip-icon.zulip-icon-link-alt::before {
    content: "\f140";
}
.zulip-icon.zulip-icon-link::before {
    content: "\f141";
}
.zulip-icon.zulip-icon-lock::before {
    content: "\f142";
}
.zulip-icon.zulip-icon-log-in::before {
    content: "\f143";
}
.zulip-icon.zulip-icon-log-out::before {
    content: "\f144";
}
.zulip-icon.zulip-icon-manage-search::before {
    content: "\f145";
}
.zulip-icon.zulip-icon-mark-as-read::before {
    content: "\f146";
}
.zulip-icon.zulip-icon-mark-as-unread::before {
    content: "\f147";
}
.zulip-icon.zulip-icon-masked-unread::before {
    content: "\f148";
}
.zulip-icon.zulip-icon-math::before {
    content: "\f149";
}
.zulip-icon.zulip-icon-maximize-diagonal::before {
    content: "\f14a";
}
.zulip-icon.zulip-icon-message-square::before {
    content: "\f14b";
}
.zulip-icon.zulip-icon-monitor::before {
    content: "\f14c";
}
.zulip-icon.zulip-icon-moon::before {
    content: "\f14d";
}
.zulip-icon.zulip-icon-more-vertical-spread::before {
    content: "\f14e";
}
.zulip-icon.zulip-icon-more-vertical::before {
    content: "\f14f";
}
.zulip-icon.zulip-icon-move-alt::before {
    content: "\f150";
}
.zulip-icon.zulip-icon-mute-new::before {
    content: "\f151";
}
.zulip-icon.zulip-icon-mute::before {
    content: "\f152";
}
.zulip-icon.zulip-icon-ordered-list::before {
    content: "\f153";
}
.zulip-icon.zulip-icon-past-time::before {
    content: "\f154";
}
.zulip-icon.zulip-icon-pin::before {
    content: "\f155";
}
.zulip-icon.zulip-icon-pipette::before {
    content: "\f156";
}
.zulip-icon.zulip-icon-play-circle::before {
    content: "\f157";
}
.zulip-icon.zulip-icon-poll::before {
    content: "\f158";
}
.zulip-icon.zulip-icon-preview::before {
    content: "\f159";
}
.zulip-icon.zulip-icon-question::before {
    content: "\f15a";
}
.zulip-icon.zulip-icon-quote-and-reply::before {
    content: "\f15b";
}
.zulip-icon.zulip-icon-quote-message::before {
    content: "\f15c";
}
.zulip-icon.zulip-icon-quote::before {
    content: "\f15d";
}
.zulip-icon.zulip-icon-readreceipts::before {
    content: "\f15e";
}
.zulip-icon.zulip-icon-recent::before {
    content: "\f15f";
}
.zulip-icon.zulip-icon-rename::before {
    content: "\f160";
}
.zulip-icon.zulip-icon-reply::before {
    content: "\f161";
}
.zulip-icon.zulip-icon-rocket::before {
    content: "\f162";
}
.zulip-icon.zulip-icon-search-inbox::before {
    content: "\f163";
}
.zulip-icon.zulip-icon-send-dm::before {
    content: "\f164";
}
.zulip-icon.zulip-icon-send::before {
    content: "\f165";
}
.zulip-icon.zulip-icon-smart-toy::before {
    content: "\f166";
}
.zulip-icon.zulip-icon-smile-bigger::before {
    content: "\f167";
}
.zulip-icon.zulip-icon-smile-smaller::before {
    content: "\f168";
}
.zulip-icon.zulip-icon-smile::before {
    content: "\f169";
}
.zulip-icon.zulip-icon-source-alt::before {
    content: "\f16a";
}
.zulip-icon.zulip-icon-source::before {
    content: "\f16b";
}
.zulip-icon.zulip-icon-spoiler::before {
    content: "\f16c";
}
.zulip-icon.zulip-icon-square-plus::before {
    content: "\f16d";
}
.zulip-icon.zulip-icon-star-filled::before {
    content: "\f16e";
}
.zulip-icon.zulip-icon-star::before {
    content: "\f16f";
}
.zulip-icon.zulip-icon-stop-circle::before {
    content: "\f170";
}
.zulip-icon.zulip-icon-strikethrough::before {
    content: "\f171";
}
.zulip-icon.zulip-icon-sun::before {
    content: "\f172";
}
.zulip-icon.zulip-icon-time::before {
    content: "\f173";
}
.zulip-icon.zulip-icon-tool::before {
    content: "\f174";
}
.zulip-icon.zulip-icon-topic::before {
    content: "\f175";
}
.zulip-icon.zulip-icon-trash::before {
    content: "\f176";
}
.zulip-icon.zulip-icon-triple-users::before {
    content: "\f177";
}
.zulip-icon.zulip-icon-unmute-new::before {
    content: "\f178";
}
.zulip-icon.zulip-icon-unmute::before {
    content: "\f179";
}
.zulip-icon.zulip-icon-unordered-list::before {
    content: "\f17a";
}
.zulip-icon.zulip-icon-unpin::before {
    content: "\f17b";
}
.zulip-icon.zulip-icon-unread::before {
    content: "\f17c";
}
.zulip-icon.zulip-icon-user-circle-active::before {
    content: "\f17d";
}
.zulip-icon.zulip-icon-user-circle-idle::before {
    content: "\f17e";
}
.zulip-icon.zulip-icon-user-circle-offline::before {
    content: "\f17f";
}
.zulip-icon.zulip-icon-user-cog::before {
    content: "\f180";
}
.zulip-icon.zulip-icon-user-list::before {
    content: "\f181";
}
.zulip-icon.zulip-icon-user-plus::before {
    content: "\f182";
}
.zulip-icon.zulip-icon-user::before {
    content: "\f183";
}
.zulip-icon.zulip-icon-video-call::before {
    content: "\f184";
}
.zulip-icon.zulip-icon-voice-call::before {
    content: "\f185";
}
.zulip-icon.zulip-icon-x-circle::before {
    content: "\f186";
}
